import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WeddingPage = () => (
  <Layout>
    <SEO title="weddings" />
    <h1>Weddings</h1>
    <iframe title="wedding-video" width="560" height="315" src="https://www.youtube.com/embed/-efTYv4kfKs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Layout>
)

export default WeddingPage
